var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[(_vm.isFetching)?_c('b-skeleton',{attrs:{"height":"400"}}):[_c('h2',{staticClass:"title is-4"},[_vm._v(" Ventes réalisées grâce aux affiliés ")]),_c('b-table',{staticClass:"box",attrs:{"loading":_vm.isLoading,"data":_vm.sales.data,"per-page":_vm.sales.meta.per_page,"total":_vm.sales.meta.total,"backend-pagination":"","paginated":""},on:{"page-change":function($event){return _vm.getSales($event)}},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_vm._v(" Aucune vente. ")]},proxy:true}])},[_c('b-table-column',{attrs:{"field":"training.name","label":"Formation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('router-link',{staticClass:"has-text-black",attrs:{"to":{name: 'training', params: {uuid: row.training.uuid}}}},[_vm._v(" "+_vm._s(row.training.name)+" ")])]}}])}),_c('b-table-column',{attrs:{"field":"created_at","label":"Vendue","width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',[_c('b-tooltip',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm._f("moment")(_vm._f("momentFromUTC")(row.created_at),'DD/MM/YY [à] HH:mm'))+" ")]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm._f("momentFromUTC")(row.created_at),'from'))+" ")])],1),(row.transaction_type === 'MULTIPLE')?_c('p',{staticClass:"mt-2 has-text-weight-bold"},[_vm._v(" Paiement en plusieurs fois ")]):_vm._e()]}}])}),_c('b-table-column',{attrs:{"label":"Transaction","width":"140"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_c('p',[_c('strong',[_vm._v("Prix :")]),_c('br'),_vm._v(" "+_vm._s(_vm._f("formatPrice")(row.transaction_amount))+" ")]),_c('p',{staticClass:"mt-2"},[_c('strong',[_vm._v("Commission :")]),_c('br'),_vm._v(" "+_vm._s(_vm._f("formatPrice")(row.affiliation_amount))+" ")])])]}}])}),_c('b-table-column',{attrs:{"label":"Profils"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_c('div',{staticClass:"break-words"},[_c('p',{staticClass:"has-text-weight-bold"},[_vm._v("Affilié")]),_c('b-tooltip',{attrs:{"position":"is-left","multilined":""},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"has-text-left content"},[_c('p',[_vm._v("Prénom : "+_vm._s(row.customer.firstname))]),_c('p',[_vm._v("Nom : "+_vm._s(row.customer.lastname))]),_c('p',[_vm._v("E-mail : "+_vm._s(row.customer.email))]),(row.customer.last_login_at)?_c('p',[_vm._v(" Dernière connexion : "),_c('br'),_vm._v(" "+_vm._s(_vm._f("moment")(_vm._f("momentFromUTC")(row.customer.last_login_at),'DD/MM/YYYY [à] HH:mm'))+" ")]):_c('p',[_vm._v(" Jamais connecté ")])])]},proxy:true}],null,true)},[_c('router-link',{attrs:{"to":{name: 'affiliate', params: {uuid: row.affiliate.uuid}}}},[_vm._v(" "+_vm._s(row.customer.firstname)+" "+_vm._s(row.customer.lastname)+" ")])],1)],1),_c('div',{staticClass:"mt-2 break-words"},[_c('p',{staticClass:"has-text-weight-bold"},[_vm._v("Apprenant")]),_c('b-tooltip',{attrs:{"position":"is-left","multilined":""},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"has-text-left content"},[_c('p',[_vm._v("Prénom : "+_vm._s(row.buyer.firstname))]),_c('p',[_vm._v("Nom : "+_vm._s(row.buyer.lastname))]),_c('p',[_vm._v("E-mail : "+_vm._s(row.buyer.email))]),(row.buyer.last_login_at)?_c('p',[_vm._v(" Dernière connexion : "),_c('br'),_vm._v(" "+_vm._s(_vm._f("moment")(_vm._f("momentFromUTC")(row.buyer.last_login_at),'DD/MM/YYYY [à] HH:mm'))+" ")]):_c('p',[_vm._v(" Jamais connecté ")])])]},proxy:true}],null,true)},[_c('router-link',{attrs:{"to":{name: 'customer', params: {uuid: row.buyer.uuid}}}},[_vm._v(" "+_vm._s(row.buyer.firstname)+" "+_vm._s(row.buyer.lastname)+" ")])],1)],1)])]}}])})],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }