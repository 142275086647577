<script>
import { mapGetters } from 'vuex';
import utilsMixin from '@shared/mixins/utils';
import APIAffiliate from '@app/services/API/Affiliate';

export default {
  mixins: [utilsMixin],
  data: () => ({
    isFetching: false,
    isLoading: false,
    sales: {
      data: [],
    },
  }),
  computed: {
    ...mapGetters('auth', {
      authStore: 'store',
    }),
  },
  methods: {
    getSales(page = 1, firstFetch = false) {
      if (firstFetch) {
        this.isFetching = true;
      } else {
        this.isLoading = true;
      }

      APIAffiliate.getAffiliatesSales(
        { storeUUID: this.authStore.uuid },
        { page },
      )
        .then((data) => (this.sales = data))
        .finally(() => {
          if (firstFetch) {
            this.isFetching = false;
          } else {
            this.isLoading = false;
          }
        });
    },
  },
  created() {
    this.getSales(1, true);
  },
};
</script>

<template>
  <section>
    <b-skeleton v-if="isFetching" height="400" />
    <template v-else>
      <h2 class="title is-4">
        Ventes réalisées grâce aux affiliés
      </h2>
      <b-table
        class="box"
        :loading="isLoading"
        :data="sales.data"
        :per-page="sales.meta.per_page"
        :total="sales.meta.total"
        backend-pagination
        paginated
        @page-change="getSales($event)"
      >
        <b-table-column
          field="training.name"
          label="Formation"
          v-slot="{ row }"
        >
          <router-link
            class="has-text-black"
            :to="{name: 'training', params: {uuid: row.training.uuid}}"
          >
            {{ row.training.name }}
          </router-link>
        </b-table-column>
        <b-table-column
          field="created_at"
          label="Vendue"
          width="180"
          v-slot="{ row }"
        >
          <p>
            <b-tooltip>
              <template #content>
                {{ row.created_at | momentFromUTC | moment('DD/MM/YY [à] HH:mm') }}
              </template>
              {{ row.created_at | momentFromUTC | moment('from') }}
            </b-tooltip>
          </p>
          <p
            v-if="row.transaction_type === 'MULTIPLE'"
            class="mt-2 has-text-weight-bold"
          >
            Paiement en plusieurs fois
          </p>
        </b-table-column>
        <b-table-column
          label="Transaction"
          width="140"
          v-slot="{ row }"
        >
          <div>
            <p>
              <strong>Prix :</strong>
              <br>
              {{ row.transaction_amount | formatPrice }}
            </p>
            <p class="mt-2">
              <strong>Commission :</strong>
              <br>
              {{ row.affiliation_amount | formatPrice }}
            </p>
          </div>
        </b-table-column>
        <b-table-column
          label="Profils"
          v-slot="{ row }"
        >
          <div>
            <div class="break-words">
              <p class="has-text-weight-bold">Affilié</p>
              <b-tooltip position="is-left" multilined>
                <template #content>
                  <div class="has-text-left content">
                    <p>Prénom : {{ row.customer.firstname }}</p>
                    <p>Nom : {{ row.customer.lastname }}</p>
                    <p>E-mail : {{ row.customer.email }}</p>
                    <p v-if="row.customer.last_login_at">
                      Dernière connexion :
                      <br>
                      {{ row.customer.last_login_at | momentFromUTC | moment('DD/MM/YYYY [à] HH:mm') }}
                    </p>
                    <p v-else>
                      Jamais connecté
                    </p>
                  </div>
                </template>
                <router-link
                  :to="{name: 'affiliate', params: {uuid: row.affiliate.uuid}}"
                >
                  {{ row.customer.firstname }} {{ row.customer.lastname }}
                </router-link>
              </b-tooltip>
            </div>
            <div class="mt-2 break-words">
              <p class="has-text-weight-bold">Apprenant</p>
              <b-tooltip position="is-left" multilined>
                <template #content>
                  <div class="has-text-left content">
                    <p>Prénom : {{ row.buyer.firstname }}</p>
                    <p>Nom : {{ row.buyer.lastname }}</p>
                    <p>E-mail : {{ row.buyer.email }}</p>
                    <p v-if="row.buyer.last_login_at">
                      Dernière connexion :
                      <br>
                      {{ row.buyer.last_login_at | momentFromUTC | moment('DD/MM/YYYY [à] HH:mm') }}
                    </p>
                    <p v-else>
                      Jamais connecté
                    </p>
                  </div>
                </template>
                <router-link
                  :to="{name: 'customer', params: {uuid: row.buyer.uuid}}"
                >
                  {{ row.buyer.firstname }} {{ row.buyer.lastname }}
                </router-link>
              </b-tooltip>
            </div>
          </div>
        </b-table-column>

        <template #empty>
          Aucune vente.
        </template>
      </b-table>
    </template>
  </section>
</template>
